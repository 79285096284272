<template>
  <div class="wrapper">
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" :rules="rules" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="地点" prop="address">
            <el-input v-model="form.address" placeholder="请输入地点" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="受益人总数" prop="benefPersonCnt">
            <el-input v-model="form.benefPersonCnt" placeholder="请输入受益人总数" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="开始时间">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd hh:mm:ss"
              v-model="form.beginTime"
              type="date"
              placeholder="请选择开始时间"
              auto-complete="off"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="参与机构Id">
            <el-input v-model="form.partOrgId" placeholder="请输入参与机构Id" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="参与机构名称">
            <el-input v-model="form.partOrgName" placeholder="请输入参与机构名称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="照片">
            <el-dialog title="导入照片" :visible.sync="importVisible" width="30%">
              <el-upload class="upload-demo" action="" :before-upload="handleUpload">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <div class="file-list">
                <p>{{ fileName }}</p>
              </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="importVisible = false">取 消</el-button>
                <el-button type="primary" @click="importSubmit">确 定</el-button>
              </span>
            </el-dialog>
            <vxe-button status="my-orange" icon="fa fa-plus" @click="importData">上传照片</vxe-button>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="服务内容">
            <el-input v-model="form.serviceContent" placeholder="请输入服务内容" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主办方Id">
            <el-input v-model="form.sponsorId" placeholder="请输入主办方Id" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="主办方名称">
            <el-input v-model="form.sponsorName" placeholder="请输入主办方名称" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="主题">
            <el-input v-model="form.topic" placeholder="请输入主题" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="8">
          <el-form-item label="主题">
            <el-input v-model="form.topic" placeholder="请输入主题" auto-complete="off"></el-input>
          </el-form-item>
        </el-col> -->
           <el-col :span="8">
          <el-form-item label="结束时间">
            <el-date-picker
              style="width: 100%"
              value-format="yyyy-MM-dd hh:mm:ss"
              v-model="form.endTime"
              type="date"
              placeholder="请选择开始时间"
              auto-complete="off"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item style="text-align: center" label-width="0">
            <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')">保存</el-button>
            <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/store/API/api'
import axios from 'axios'
export default {
  name: 'AddOrEdit',
  data() {
    return {
      fileName: '',
      importVisible: false,
      titleName: '',
      form: {
      },
      tablePage: {
        totalResult: 0,
        currentPage: 0,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 200, 500, 1000],
        perfect: true,
      },
      plateNoId: '',
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      },
      sexList: [
        {
          value: '男',
          label: '男',
        },
        {
          value: '女',
          label: '女',
        },
      ],
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    },
    searchist() {
      var list = []
      var _list = []
      var _this = this
      this.seqList.map(function (item) {
        if (item.plateNo.indexOf(_this.plateNoId) != -1) {
          //然后把当前json添加到list数组中
          _list.push(item)
          list = _list
        }
      })
      return list
    },
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions(['agedMarketSave', 'updateWorkPersonServe']),
    focusEvent1() {
      this.$refs.xDown1.showPanel()
    },
    getFactTime(data) {
      this.form.factTime = data
    },
    // 上传照片
    importData() {
      this.importVisible = true
    },
    handleUpload(file) {
      let suffix = file.name.split('.')[1]
      if ((suffix = '')) {
        this.$XModal.message({
          message: '格式不正确',
          status: 'warning',
        })
        return false
      }
      this.fileData = file
      this.fileName = file.name
      return false
    },
    importSubmit() {
      console.log('111111', this.fileData)
      if (this.fileData) {
        let formData = new FormData()
        formData.append('file', this.fileData)
        let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
        url += api.fileUpload
        axios({
          method: 'post',
          data: formData,
          url,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: sessionStorage.token,
          },
        }).then((res) => {
          console.log(res, '上传图片返回值')
          this.importVisible = false
          this.form.imgs = res.data.data
        })
      } else {
        this.$XModal.message({
          message: '请上传模板文件',
          status: 'warning',
        })
      }
    },
    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加机构'
      } else {
        this.titleName = '修改机构'
        this.itemData = JSON.parse(this.$route.query.item)
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.form = {
          address: this.itemData.address,
          beginTime: this.itemData.beginTime,
          benefPersonCnt: Number(this.benefPersonCnt),
          endTime: this.itemData.endTime,
          imgs: this.itemData.imgs,
          topic: this.topic,
          partOrgName: this.partOrgName,
          serviceContent: this.serviceContent,
          sponsorId: Number(this.sponsorId),
          sponsorName: this.sponsorName,
          partOrgId: Number(this.itemData.partOrgId),
          id: Number(this.itemData.id),
        }
      }
    },
    //区域选择
    selectArea(data) {
      this.form.teamName = ''
      this.teamByAreaList = ''
      this.getTeamListByArea(data) //获取队伍
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {}
          // 字符串转化为数组
          this.form.benefPersonCnt = parseInt(this.form.benefPersonCnt)
          this.form.partOrgId = parseInt(this.form.partOrgId)
          this.form.sponsorId = parseInt(this.form.sponsorId)
          params = this.form
          let server = this.routeType == 'add' ? 'agedMarketSave' : 'agedMarketSave'
          this[server](params).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: this.routeType == 'add' ? '添加成功' : '修改成功',
                status: 'success',
              })
              this.$router.go(-1)
            }
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow: hidden;
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }
  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;
    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }
}
</style>
